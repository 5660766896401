$Color-Key-1: #FFFFFF;
$Color-Key-2: #FFFFFF;

$Color-Spot-1: #FFEF75;
$Color-Skin-1: #06AA70;
$Color-Skin-1-Soft: #06AA70;
$Color-Skin-1-Pop: #058F5E;
$Color-Shade: #058F5E;

$Color-Spot-2: #006DB0;
$Color-Skin-2: #FFEF75;
$Color-Skin-2-Soft: #e3d568;
$Color-Skin-2-Pop: #FFEF75;

$Color-Spot-3: #FFEF75;
$Color-Skin-3: #338988;
$Color-Skin-3-Soft: #338988;
$Color-Skin-3-Pop: #2C7776;


$Color-Trinity-Progress: #F8AB44;

$size1: 700px;
$size2: 650px;
$size3: 600px;

.roundup-font-micro {
  font-family: Barlow;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.40625rem */
  letter-spacing: 0.01875rem;
}

.roundup-font-body {
  font-family: Barlow;
  font-size: 1.25rem;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.025rem;
}

.roundup-font-body-emphasis {
  font-family: Barlow;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.025rem;
}

.roundup-font-body-strong {
  font-family: Barlow;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.025rem;
}

.roundup-font-body-strong-alt {
  font-family: "Barlow Condensed";
  text-align: center;
  font-size: 1.5625rem;
  font-style: italic;
  font-weight: 800;
  line-height: 150%; /* 2.34375rem */
  letter-spacing: 0.03125rem;
}

.roundup-font-head-small {
  font-family: Barlow;
  font-size: 1.71875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.89063rem */
  letter-spacing: 0.043rem;
}

.roundup-font-head-small-emphasis {
  font-family: Barlow;
  font-size: 1.71875rem;
  font-style: italic;
  font-weight: 700;
  line-height: 110%; /* 1.89063rem */
  letter-spacing: 0.0215rem;
}

.roundup-font-head-emphasis {
  font-family: "Barlow Condensed";
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 800;
  line-height: 100%; /* 2.5rem */
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}

.roundup-font-head-larger-emphasis {
  font-family: "Barlow Condensed";
  font-size: 5rem;
  font-style: italic;
  font-weight: 800;
  line-height: 90%; /* 4.5rem */
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

.roundup-font-title {
  text-align: center;
  font-family: "Barlow Condensed";
  font-size: 1.875rem;
  font-style: italic;
  font-weight: 800;
  line-height: 100%; /* 1.875rem */
  letter-spacing: 0.05625rem;
  text-transform: uppercase;
}

.roundup-font-title-strong {
  font-family: Barlow;
  text-align: center;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.1875rem */
  letter-spacing: 0.04375rem;
}

.roundup-font-title-strong-alt {
  font-family: "Barlow Condensed";;
  text-align: center;
  font-size: 2.1875rem;
  font-style: italic;
  font-weight: 800;
  line-height: 100%; /* 2.1875rem */
  letter-spacing: 0.06563rem;
}

.roundup-font-title-large {
  text-align: center;
  font-family: "Barlow Condensed";
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 800;
  line-height: 100%; /* 2.5rem */
  letter-spacing: 0.075rem;
  text-transform: uppercase;
}

.roundup-font-subTitle {
  text-align: center;
  font-family: Barlow;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1.875rem */
  letter-spacing: 0.0375rem;
}

.roundup-font-subTitle-emphasis-alt {
  font-family: Barlow;
  text-align: center;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.875rem */
  letter-spacing: 0.0375rem;
}

.roundup-font-subTitle-emphasis-alt-2 {
  font-family: "Barlow Condensed";
  text-align: center;
  font-size: 1.875rem;
  font-style: italic;
  font-weight: 800;
  line-height: 110%; /* 2.0625rem */
  letter-spacing: 0.05625rem;
}

.roundup-font-subTitle-emphasis {
  font-family: Barlow;
  text-align: center;
  font-size: 1.71875rem;
  font-style: italic;
  font-weight: 700;
  line-height: 110%; /* 1.89063rem */
  letter-spacing: 0.0215rem;
}
