.stages-media-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.slide-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
