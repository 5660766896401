.terms-by-dd {
  float: left;
  font-style: italic;
  color: #999;
}

.concert-permission-text {
  font-style: italic;
  color: #999;
}

.mb22 {
  margin-bottom: 22px;
}

.would-do-option {
  margin: 0 15px 15px 0;
}

.button-link {
  background-color: transparent;
  border: 0;
  color: #4183c4;
  cursor: pointer;
  font-family: inherit;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.kid-button-group {
  float: right;
  position: relative;
  top: -8px;
}

.kid-button-group .ui.button {
  padding: 10px 10px;
  margin-left: 0;
}

.enrol-kid-container {
  padding-top: 25px;
}

.choose-instrument-button {
  margin: 15px !important;
  min-width: 250px;
}

.savebtn-centered {
  text-align: center;
}

.savebtn-hidden {
  height: 0;
  overflow: hidden;
  transition: height 0.2s 0.2s, overflow 0.2s 0.2s;
}

.savebtn-sticky {
  height: 48px;
  overflow: hidden;
  position: sticky;
  top: 16px;
  z-index: 100;
  transition: height 0.2s 0.1s, overflow 0.2s 0.1s;
}

.homepage-child-timelines {
  margin-top: 15px;
}

.child-video-entry-wrapper {
  width: 90%;
  margin: auto;
  padding: 10px;

  .ui.image.slide-image {
    margin: 0;
  }
}

.child-image-entry-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 10px 0;
}

.ui.message.child-entry-summary {
  color: $rocksteady-blue;
  font-size: 16px;
  margin-bottom: 4px;

  .summary-flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .circular-label {
    background-color: $rocksteady-blue;
    color: $white;
    font-weight: 500;
  }

  .name {
    padding: 10px;
  }
}

.ui.message.child-entry {
  background-color: #e5f0fa;

  .content {
    min-height: 40px;

    .ui.grid {
      margin-top: 0;
    }
  }

  img {
    float: left;
  }

  .label {
    font-size: 12px;
    background-color: white;
    border-radius: 2px;
    // The border color should come from the serialized timeline entry
    // If it is missing, the color will default to the text color, off-black
    border-left: 4px solid;
  }
}

@media only screen and (max-width: 768px) {
  .kid-button-group {
    float: none;
    position: relative;
    top: 0;
    padding-top: 10px;
  }

  .kid-button-group .button {
    margin: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .ui.message.child-entry .text {
    line-height: 32px;
  }
}
