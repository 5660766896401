@import './colors.scss';

#upsell-choices {
  margin-top: 24px;
}

.instrument-card {
  border-radius: 5px;
  padding: 15px 10px;
  border: 1px solid $white;
  background-color: transparent;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);

  &.white-background {
    background-color: $white;
  }

  &.blue-border {
    border-color: $rocksteady-blue;
  }

  .red-text {
    color: $red;
  }

  .green-text {
    color: #007859;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .flex-row-aligned-center {
    display: flex;
    align-items: center;
  }

  .centered {
    justify-content: center;
  }

  .space-between {
    justify-content: space-between;
  }

  img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .full-width {
    width: 100%;
  }

  .max-half-width {
    max-width: 50%;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .px-16 {
    padding: 0 16px;
  }

  .double-instruments {
    @media only screen and (max-width: 767px) {
      width: 100%;

      strong, .red-text, .green-text {
        font-size: smaller; 
      }
    }

    @media only screen and (max-width: 480px) {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
