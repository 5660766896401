.ui.button:disabled,
.ui.positive.button:disabled,
.ui.green.button:disabled,
.ui.blue.button:disabled {
  background-color: #c4c7cf;
}

.ui.button {
  @include brandBodyFont;
  margin: 0;
  text-transform: uppercase;
  transform: skew(-7deg);
  border-radius: 0;

  &:after {
    content: "";
    transform: skew(-7deg);
  }
}

.ui {
  .primary.button,
  .blue.button {
    background-color: $mrBlueSky;
    &:hover {
      background-color: $oceanColourScene;
    }
  }
  .positive.button {
    background-color: $greenGreenGrass;
    &:hover {
      background-color: $greenDay;
    }
  }
}

.active.content .icon {
  float: right;
}

.ui.blue.inverted.segment {
  background-color: #00c3ef;
}

.ui.green.inverted.segment {
  background-color: $rsplay-green !important;
}

.ui.green.inverted.segment p {
  color: $text-grey;
}

.ui.basic.button,
.ui.basic.button:active,
.ui.basic.button:focus {
  box-shadow: 0 0 0 1px #fff inset !important;
  color: #fff !important;
  background-color: transparent !important;
}

.ui.basic.button:hover {
  background-color: #fff!important;
  color: $mrBlueSky !important;
}

.ui.blue.basic.button,
.ui.blue.basic.button:active,
.ui.blue.basic.button:focus  {
  box-shadow: 0 0 0 1px $mrBlueSky inset !important;
  color: $mrBlueSky !important;
  background-color: transparent !important;
}

.ui.blue.basic.button:hover{
  color: #fff !important;
  background-color: $mrBlueSky !important;
}

.ui.segment {
  border-radius: 0;
  box-shadow: none;
  background: #f5f5f4;
  border: none;
  padding: 30px 20px;
}

.ui.message {
  border-radius: 0;
  box-shadow: none;
  @include brandGradient($tintPurpleRain, lighten($tintPurpleRain, 6%));
  padding: 16px;
}

.ui.message.padded {
  padding: 32px;
}

.ui.label {
  border-radius: 1rem;
}

.ui.form .field>label {
  color: $text-grey;
}

.ui.styled.accordion, .ui.styled.accordion .accordion {
  border-radius: 0;
  box-shadow: none;
  border: 1px $purpleRain solid;
}

.ui.accordion .title,
.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title  {
  @include brandBodyFont;
  color: $blackSabbath;
}

.ui.styled.accordion .active.title {
  color: $text-grey;
}

.dd-qualifier-form .ui.grid {
  margin: 0;
}

.dd-qualifier-text {
  font-style: italic;
}

.ui.info.message {
  border-radius: 0;
  box-shadow: none;
  background: #d2f1fb;
}

.ui.attached.positive.message,
.ui.positive.message {
  box-shadow: none;
}

.ui.positive.message {
  @include brandGradient($tintGreenGreenGrass, lighten($tintGreenGreenGrass, 6%));
  border-radius: 0;
  border-color: $greenGreenGrass;
  color: $greenDay;
}

.ui.negative.message {
  @include brandGradient($tintRedAlert, lighten($tintRedAlert, 6%));
  border-radius: 0;
  border-color: $redAlert;
  color: $redHotChilliPeppers;
  box-shadow: none;
}

.ui.card,
.ui.cards > .card {
  background-color: #f5f5f4;
  border-radius: 0;
  box-shadow: none;
}

.ui.cards > .card .image {
  padding: 15px 15px 5px 15px;
}

.ui.card > .image > img,
.ui.cards > .card > .image > img {
  border-radius: 0;
}

.ui.card > .extra,
.ui.cards > .card > .extra {
  border-top: none !important;
  padding-bottom: 15px;
  padding-top: 5px;
}

.ui.card > .image,
.ui.cards > .card > .image {
  background: none;
}

.ui.card > .content,
.ui.cards > .card > .content {
  border-top: none;
}

.ui.table {
  border-radius: 0;
  border: none;
  color: $header-black;
}

.ui.celled.table tr td,
.ui.celled.table tr th {
  border: none;
}

.ui.ui.striped.table {
  background-color: rgba(210, 241, 251, 0.5);
}

.ui.striped.table tr th {
  background-color: $rocksteady-blue;
  color: $white;
}

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table > tr:nth-child(2n) {
  background-color: rgba(210, 241, 251, 1);
}

.ui.button.wrapping-button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.ui.label.wrapping-label {
  margin-bottom: 6px;
  margin-right: 6px;
}

.ui.icon.button > i.icon.padded-icon {
  margin-bottom: 8px !important;
}

.ui.list.feedbackRehearse {
  padding-left: 10px;
}

.ui.menu-button:active, .ui.menu-button:focus, .ui.menu-button:hover {
  background-color: $mrBlueSky;
  color: white;
}

.ui.grid >.row.main-heading {
  margin-top: 55px;
}

.hamburger-menu .ui.accordion .title:not(.ui) {
  padding-top: 0;
  color: $whitesnake;
  font-size: 24px;
  margin: 45px 10px 0 0;
}

.ui.vertical.menu .item i.icon {
  float: left;
  margin-right: 10px;
}

.ui.secondary.vertical.menu {
  border-bottom: 1px solid black;
}

.ui.secondary.vertical.mobile-menu {
  margin-top: 20px;
  width: unset !important;
  border: 0px solid #E5EAEF;
  border-radius: 0px;
  // background-color: #004835;

  .item {
    padding: 11px 20px 13px 20px;
    border-radius: 0;
  }
}

.instrument-preferences {
  padding-left: 0.75em;
}

.ui.checkbox label {
  font-weight: 400;
  color: $text-grey;
}

.ui.checkbox input[disabled]~.box:after, .ui.checkbox input[disabled]~label, .ui.disabled.checkbox .box:after, .ui.disabled.checkbox {
  color: $header-black;
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 100%;
    padding: 0 118px;
  }

  .ui.form .required.field .checkbox:after {
    top: 5px;
    left: 98%;
  }
}

// Tablet landscape
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.container {
    width: 100%;
    padding: 0 20px;
  }

  .ui.secondary.vertical.menu .item {
    padding-left: 0;
    padding-right: 0;
    margin-left: -5px;

    .icon {
      margin-right: 10px;
    }
  }

  .ui.form .required.field .checkbox:after {
    top: 5px;
    left: 95%;
  }
}

// Tablet portrait
@media (max-width: 992px) and (min-width: 769px) {
  .ui.container {
    width: 100%;
    padding: 0;
  }

  .ui.secondary.menu .item {
    padding: 5px 0;
  }

  .ui.relaxed.grid>.row>.menu-v1 {
    padding-left: 30px;
    padding-right: 0;
  }
}

// Mobile and tablet in portrait
@media only screen and (max-width: 768px) {
  .ui.basic.button:hover {
    box-shadow: 0 0 0 1px #fff inset !important;
    color: #fff !important;
    background-color: transparent !important;
  }

  .ui.blue.basic.button:hover{
    box-shadow: 0 0 0 1px $mrBlueSky inset !important;
    color: $mrBlueSky !important;
    background-color: transparent !important;
  }

  .ui.container {
    width: 100%;
    padding: 0 21px;
    margin: 0 !important;
  }

  .ui.segment {
    padding: 15px;
  }

  .instrument-select .ui.segment {
    padding: 30px;
    margin-left: -10px;
  }

  div.ui.stackable.grid > div.row.main-heading {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  div.ui.stackable.grid > div.row.main-heading > div.column.heading-dropdown {
    padding-top: 0 !important;
  }

  .ui.selection.dropdown .menu {
    max-height: fit-content;
  }

  .ui.secondary.menu {
    margin: 0;
  }

  .ui.secondary.menu .item {
    padding: 11px 3px 13px 0;
  }

  .ui.table .summary-column-title {
    min-width: auto;
  }

  .ui.message .header, .ui.message p {
    margin-right: 12px;
  }

  .ui.form .required.field .checkbox:after {
    top: 5px;
    left: 92%;
  }
}

// Tablet portrait only
@media only screen and (width: 768px) {
  .ui.info.message {
    margin: 0 1.5rem;
  }
}

// Mobile excluding tablet
@media only screen and (max-width: 767px) {
  .ui.grid, .ui.relaxed.grid {
    margin-left: 0;
    margin-right: 0;
  }

  .ui.grid .ui.stackable.grid {
    margin: 0 !important;
  }

  .ui.stackable.grid .row .column, .ui.stackable.grid .row .wide.column, .ui.stackable.grid .column .row .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ui.form .required.field .checkbox:after {
    padding-top: 5px;
    left: unset;
    right: 10px;
  }
}

// Tablet and larger
@media only screen and (min-width: 768px) {
  .centered.row {
    margin: 0 1.5rem;
  }
}
