.ui.menu {
  button {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    -webkit-appearance: none;
  }
  .item {
    color: $rocksteady-blue;
    @include brandBodyFont;
    font-size: 16px;
    height: 46px;
    line-height: 24px;
    .avatar {
      display: inline;
      width: 18px;
      height: 18px;
      margin-left: 8px;
      margin-right: 10px;
    }
  }
  .circular-label {
    background-color: $rocksteady-blue;
    color: $white;
    font-weight: 500;
  }
  .sub-menu-link-wrapper {
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      background-color: #fff;
      list-style-type: none;
      margin-left: 8px;
      margin-bottom: 7px;
      border-radius: 0px;
      padding: 8px 11px 8px 11px;
    }
  }
  .sub-menu-item {
    color: $rocksteady-blue;
    @include brandBodyFont;
    font-size: 16px;
    padding: 12.5714px 14.8571px 12.5714px 14.8571px;
    line-height: 24px;
    border-radius: 0px;
    .avatar {
      display: inline;
      width: 18px;
      height: 18px;
      margin-left: 8px;
      margin-right: 10px;
    }
  }
  .sub-menu-welcome-wrapper {
    margin-bottom: 10px;
  }
  .sub-menu-links {
    padding: 0px 14.8571px 12.5714px 14.8571px;
  }
  .sub-menu-item-welcome {
    background-color: #EDF2F7;
    color: $rocksteady-blue;
    @include brandBodyFont;
    font-size: 18px;
    padding: 12.5714px 14.8571px 12.5714px 14.8571px;
    border-radius: 0px;
    margin-bottom: 10px;
    min-height: 75px;
    .header {
      line-height: 49.8518px;
    }
    .avatar {
      display: inline;
      width: 25px;
      height: 25px;
      margin-left: 8px;
      margin-right: 10px;
    }
  }
}

.ui.secondary.vertical.menu {
  border-bottom: 1px solid $line-grey;

  .item {
    border-top: 1px solid $line-grey;
    margin-bottom: 0;
  }

  .item:hover, .item:active {
    color: $rocksteady-blue;
    background-color: $ultra-pale-blue;
  }
}

.ui.relaxed.grid .row .menu-v1 {
  padding: 0;
}

.hamburger-menu {
  float: right;
  color: $rocksteady-blue;
}

.custom-icon {
  float: left;
  height: 18px;
  width: 18px;
  opacity: .9;
  margin: 2px 10px 0 0.5em;
}
