@import '../../styles/colors.scss';
@import '../../styles/utils.scss';

#site-footer-wrapper {
  color: $whitesnake;
  margin-top: 100px;
  @include brandGradient($deepPurple, $theMoodyBlues);
  clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%);
  padding-top: 120px;
  padding-bottom: 200px;
}

#site-footer {
  display: flex;
  font-size: 14px;
  margin-top: 25px;
}

#site-footer-left-column {
  width: 100%;
}

#site-footer .contact {
  float: left;
}

#site-footer .contact span {
  font-size: 22px;
  font-weight: normal;
  color: #292b32;
}

#site-footer .contact .need-help {
  font-size: 14px;
  color: #292b32;
}

#site-footer .terms {
  margin-bottom: 20px;
}

#site-footer .terms a {
  color: $whitesnake;
  margin-right: 20px;
}

#site-footer .terms a:hover {
  color: $whitesnake;
  text-decoration: underline;
}

#site-footer .social-media a {
  margin-bottom: 20px;
}

#site-footer .social-media {
  margin: 0 0 20px 0;
}

#site-footer .social-media img:hover {
  transform: scale(1.2);
}

#site-footer .social-media img {
  padding: 0 35px 0 0;
}

#site-footer .copyright {
  margin-bottom: 0;
}

#site-banner {
  display: flex;
  justify-content: center;
}

#banner-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

#banner {
  max-width: 100%;
  position: relative;
  width: 650px;
}

#banner-text {
  color: #ffffff;
  font-family: "Barlow Condensed", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1.5rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
  text-transform: uppercase;
}

#site-banner img {
  width: 100%;
}

#site-footer-right-column img {
  height: 200px;
  margin-bottom: 20px;
  position: relative;
  top: -55px;
}

@media only screen and (max-width: 767px) {
  #site-footer {
    flex-direction: column-reverse;
  }

  #banner {
    width: 100%;
  }

  #site-footer-right-column img {
    height: 150px;
    margin-bottom: 20px;
    float: right;
  }

  #site-footer .terms a {
    clear: left;
    float: left;
    margin-bottom: 20px;
    width: 100%;
  }
}

@media only screen and (width: 768px) {

}
