.instrument-select {
  text-transform: uppercase;
}

.instrument-select .ui.grid {
  margin-bottom: 10px;
}

.lesson-difficulty .menu {
  min-height: 155px;
}

.instrument-select .column {
  padding-bottom: 0px !important;
}
.instrument-select .grey {
  color: #999;
}

.instrument-select.ui.inverted.segment label {
  color: #fff;
}
.instrument-select .ui.inverted.segment.grey {
  background: #ccc !important;
}

.instrument-select .ui.inverted.segment.grey label {
  color: #999;
}

.instrument-ability-select.ui.segment {
  padding: 20px;
}

.instrument-ability-select p:last-of-type {
  margin-bottom: 10px;
}

.ui.segment.instrument-ability-select {
  border-top: 10px solid #0072ce;
}

.ui.segment.instrument-ability-select:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  border-top: 5px solid #00e7ef;
}

.instrument-ability-select .dropdown .text {
  color: #292B32;
  font-size: 16px;
}

.instrument-ability-select .dropdown > div.menu.transtion {
  max-height: fit-content;
}

.instrument-ability-select div.ui.fluid.selection.dropdown {
  width: 65%;
  margin-bottom: 20px;
}

.instrument-ability-select-subheading {
  margin-bottom: 0;
}

.instrument-ability-select h2 {
  margin-top: 5px;
  color: #0072ce;
  font-size: 27px;
}

.instrument-ability-select p b {
  color: #0072ce;
}

.instrument-change-actions .field {
  display: inline-block;
}

.instrument-change-timetable-select {
  display: inline-block;
  margin-left: 8px;

  .ui.fluid.dropdown {
    min-width: 14em;
  }
}

@media only screen and (max-width: 767px) {
  .instrument-ability-select div.ui.fluid.selection.dropdown {
    width: 100%;
  }
}
