.instrument-change-modal {
  border-radius: 10px;
  padding: 30px 20px 30px 20px;

  .header:not(.ui) {
    border-bottom: 0;
    margin-bottom: 0.5em;
    padding: 0;
    h2 {
      font-size: 30px;
      text-transform: uppercase;
    }
  }

  .content:not(.ui) {
    padding: 0;
  }

  .actions:not(.ui) {
    background: transparent;
    border-top: 0;
    padding: 0;
    text-align: left;
    
    .button {
      margin-left: 0;
      margin-right: 1em;
      padding: 12px 30px 12px 30px;
    }
  }

  .out-of-hours-band-info {
    font-weight: bold;
    margin-bottom: 12px;
  }

  .details-box {
    background-color: rgba(210, 240, 251, 0.5);
    border-radius: 10px;
    margin: 16px 0;
    padding: 16px;

    .detail-header {
      border-radius: 60px;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 16px;
      padding: 4px 10px;
    }

    .original-timetable-header {
      background-color: #a9aaad;
      color: #FFFFFF;
    }

    .new-timetable-header {
      background-color: #00e7ef;
      color: #292B32;
    }

    .details-column {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 16px;
    }

    .details-info {
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 0;

      .row {
        padding: 0;
        .column:first-child {
          white-space: nowrap;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .instrument-change-modal {
    .actions:not(.ui) {
      .button {
        margin-left: 0;
        
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .instrument-change-modal {
    padding: 0;

    .header:not(.ui) {
      margin-top: 1rem;
    }

    .content:not(.ui) {
      margin-top: -1.5rem;
    }

    .actions:not(.ui) {
      margin-top: -2rem;
      white-space: nowrap;

      // .button {
      //   margin-right: 0;
      //   width: 48%;

      //   &:first-child {
      //     float: left;
      //   }
      //   &:last-child {
      //     float: right;
      // }
      // }
    }
  }
}
