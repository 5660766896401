/** Legacy file
 * DO NOT ADD NEW STYLING HERE
 * Please add new styling content under the component's own css / scss file, or to the scss files in the styles folder
 * Please do move or delete content from this file as appropriate when you are able to
 */

.four .column .row .column {
  min-width: 110px;
  margin-top: 25px !important;
}

.pref_inst_width {
  min-width: 196px !important;
}

.two .wide .column {
  position: relative;
  min-width: 126px;
}

.field label {
  font-size: 1rem !important;
}

.noteHeading {
  font-size: 13pt;
  font-weight: bold;
}

.slide-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid#ccc;
}

.slide-image-wrapper .ui.image {
  width: 100%;
  height: 100%;
}

.image.play-icon {
  position: absolute;
  max-height: 50%;
  top: 25%;
  opacity: 0.7;
  transition-property: opacity;
  transition-duration: 0.3s;
}

.image.play-icon:hover {
  cursor: pointer;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.3s;
}

.slide-image:hover {
  cursor: pointer;
}

.ui.thumbnail {
  padding: 0;
}

.ui.thumbnail:hover .play-icon {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

@media only screen and (max-width: 767px) {
  .rating-button {
    display: block;
    margin-bottom: 15px;
  }
}

/** Legacy file
 * DO NOT ADD NEW STYLING HERE
 * Please add new styling content under the component's own css / scss file, or to the scss files in the styles folder
 * Please do move or delete content from this file as appropriate
 */
