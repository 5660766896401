.video-modal .header.page-heading {
  color: white;
  background-color: #0072ce;
  padding: 15px;
}

.video-modal .content {
  position: relative;
}

.modal.video-modal > .actions {
  padding: 1.25rem 1.5rem;
  text-align: center;
}

.modal.video-modal > .actions h2 {
  background-color: #0072ce;
  padding: 15px;
  color: white;
}

@media only screen and (max-width: 991px) {
  .video-modal .header {
    padding: 21px !important;
  }

  .video-modal .header.page-heading {
    margin-top: 0px;
  }

  .ui.modal>.close {
    top: 1.2rem !important;
    right: 1.2rem !important;
    color: white;
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .ui.modal>.header,
  .modal.video-modal > .actions {
    padding: 14px !important;
  }

  .ui.modal>.close {
    top: .6rem !important;
    right: .6rem !important;
    color: white;
    opacity: 1;
  }

  .video-modal h2,
  .video-modal .ui.header {
    font-size: 16px;
    padding: 15px;
  }
}
