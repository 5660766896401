@import './RoundUpGlobals.scss';

.Stepper,
.StepperColorOption2,
.StepperColorOption3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $Color-Skin-1-Pop;
  padding: 10px;
  transition: all 300ms ease-in-out;
  margin: 20px 0;
  &__step,
  &__activeStep {
    background: $Color-Skin-1-Soft;
    border: none;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    transition: all 500ms;
  }
  &__activeStep {
    background: $Color-Key-1;
    opacity: 1;
  }
}

.StepperColorOption2 {
  background: $Color-Skin-2-Pop;
  &__step {
    background: $Color-Skin-2-Soft;
  }
  &__activeStep {
    background: $Color-Spot-2;
  }
}

.StepperColorOption3 {
  background: $Color-Skin-3-Pop;
  &__step {
    background: $Color-Skin-3-Soft;
  }
  &__activeStep {
    background: $Color-Key-1;
  }
}
