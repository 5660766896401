.page-container {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .page-container {
    padding: 0;
  }
}
