.quoteWrapper {
  padding-top: 30px;
}

.quoteInner {
  border-left: 1px solid #ccc;
  padding-left: 15px;
  float: left;
  width: 85%;
}

.quoteMark {
  float: left;
  font-size: 60px;
  width: 10%;
  color: #0072ce;
}

.quoteBody {
  padding: 0;
  margin: 0 0 15px;
  font-size: 20px;
  line-height: 1.3;
  color: #0072ce;
}

.quoteName {
  color: #888;
  font-size: 12px;
}
