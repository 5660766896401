.dropdown.fun-video-category > div.menu.transition {
  max-height: fit-content;
}

.ui.message.no-videos > div.header {
  font-size: 14px;
}

.fun-videos .description {
  color: rgba(0, 0, 0, 0.7)
}

.fun-videos .header {
  font-size: 14px;
  font-weight: 700;
  color: #4183c4;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.fun-videos .narrow-column {
  width: 90px;
  float: left;
  height: 40px;
}

.fun-videos .wide-column {
  width: 85%;
  float: left;
}

.fun-videos .item:after {
  content: "";
  clear: both;
}

.fun-videos .tags {
  margin-left: 90px;
  clear: both;
}

.fun-videos .tag {
  margin-top: 5px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid grey;
  border-radius: 5px;
  padding: 3px;
  display: inline-block;
}

.catchup-videos h2 {
  text-transform: none;
}

@media only screen and (max-width: 768px) {
  .fun-videos .wide-column {
    width: 70%;
  }
}
