.header-wrapper {
  height: 125px;

  background-color: $deepPurple;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);

  font-size: 16px;
  color: $whitesnake;
}

.site-header {
  @include brandBodyFont;
  position: relative;
  font-size: 15px;
  margin-left: 0;
  margin-right: 0;

  .logo {
    position: absolute;
    top: 3px;
    z-index: 99;
    height: 94px;
  }

  .header-title {
    h4 {
      @include brandSubHeading;
      background: $whitesnake;
      float: left;
      margin-left: 120px;
      margin-top: 30px;
      padding: 9px 21px;
      text-transform: uppercase;
      clip-path: polygon(5% 10%, 100% 0%, 100% 100%, 0% 90%);

      a {
        color: $deepPurple;
      }
    }
  }

  .title-links {
    margin-top: 46px;

    a {
      margin-left: 20px;
      color: $whitesnake;
    }
  }
}

.image-banner {
  background: #00e7ef;
  margin: 15px 0 15px 0;
  padding: 150px 30px 30px;
  background-image: URL('./images/portal/rehearse_at_home.jpg');
  background-size: cover;
  background-position: center;
}

.image-banner .ui.header {
  text-transform: uppercase;
  font-size: 35px;
  max-width: 240px;
  color: #fff;
  line-height: 0.88;
}

.image-banner.split {
  padding: 325px 15px 30px;
  background-image: URL('./images/portal/rah_double_banner.png');

  .ui.header {
    max-width: 100%;

    .sub.header {
      margin-top: 10px;
      margin-right: 25px;
    }
  }

  .ui.button {
    padding: 11px;
    float: right;
    margin-bottom: 5px;
  }
}

.main-heading {
  margin-top: 55px;
  margin-bottom: 25px;

  .ui.checkbox {
    margin-top: 15px;
  }

  .ui.header {
    text-transform: uppercase;
    font-size: 48px;
    color: $purpleRain;
    line-height: 1;

    .sub.header {
      color: $deepPurple;
    }
  }
}

.heading-dropdown .dropdown {
  width: 100%;
  float: right;
}

.heading-dropdown .dropdown > div.menu.transition {
  max-height: fit-content;
}

.image-banner .ui.header .sub.header {
  color: #fff;
  font-size: 20px;
}

.ui.header.page-heading {
  padding: 20px 0;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 46px;

  .content {
    color: $deepPurple;
  }
}

h2.ui.header .sub.header,
h1.ui.header .sub.header {
  font-size: 18px;
  color: $purpleRain;
}

.ui.header.secondary-heading {
  font-size: 27px;
  background: $deepPurple;
  clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 92%);
  padding: 12px 15px 12px 15px;
  text-transform: uppercase;
  color: white;
  border-bottom: 0px solid $deepPurple;
  margin-top: 15px;
  margin-bottom: 15px;
}

#landing-page-header {
  margin-top: -16px;
}

#landing-page-button {
  margin-top: -10px;
}

.rah-feedback-footer {
  color: #989ba4;
}

.rah-feedback-footer > p:first-of-type {
  color: rgba(0,0,0,0.45);
}

#password-message {
  margin-top: 20px;
}

.site-header .back-to-home-button {
  position: absolute;
  top: 40px;
  right: 0;
  padding-right: 1.5rem;
}

.site-header .back-to-home-button .ui.button {
  color: #fff;
  background-color: #0072ce;
  line-height: 1.3;
  margin-right: 0;
}

.separator {
  border-top: 10px solid #0072ce;
}

.ui.grid.main-page {
  padding-top: 44px;
}

.title-links {
  float: right;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .image-banner.split {
    .ui.header {
      font-size: 20px;
      line-height: 1.5rem;
      .sub.header {
        margin-right: 0;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #landing-page-header {
    margin-top: -16px;
    font-size: 15px;
  }
  .site-header {
    .back-to-home-button {
      top: 35px;
      right: 0;
      padding-right: 0;

      .ui.button {
        font-size: 12px;
      }
    }

  .header-title {
    h4 {
      float: right;
      margin-top: 37px;
      margin-right: 37px;
    }
  }

   .staff {
      display: none;
    }
  }

  .title-links {
    display: none;
  }

  .ui.header.page-heading {
    padding: 10px;
    font-size: 2.2rem;
    line-height: 1;

    .content {
      margin-bottom: 12px;
    }
  }

  .image-banner {
    padding: 140px 15px 15px;
    margin-bottom: 15px;
  }

  .image-banner .ui.header {
    font-size: 38px;
    max-width: 100%;
  }

  .image-banner .ui.header .sub.header {
    font-size: 18px;
  }

  .ui.grid.main-page {
    padding-top: 20px;
    width: 100%;
  }
}

// Tablet portrait
@media (max-width: 991px) and (min-width: 768px) {

}
