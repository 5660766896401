@import '../../styles/colors.scss';

.instrument-deals {
  .page-divider {
    height: 10px;
    background-color: $purpleRain;
  }

  h2,
  h3 {
    text-transform: uppercase;
    color: $deepPurple;
  }

  h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 29px;
    margin: 25px 0;
  }

  h3 {
    font-size: 24px;
    line-height: 23px;
    margin: 25px 0;
  }

  .rs-dark-color {
    color: $deepPurple;
  }

  h3.how-to-buy {
    margin-bottom: 12px;

    + p {
      margin-bottom: 30px;
    }
  }

  padding: 1.5rem;
}
