// NEW BRAND COLOURS AND NAMES (light and dark pairs)
$blueMonday: #004283;
$theMoodyBlues: #252b5a;

$mrBlueSky: #009fe3;
$oceanColourScene: #006db0;

$breakfastAtTiffanys: #69c3c6;
$aqua: #338988;

$greenGreenGrass: #06aa70;
$greenDay: #0e6348;

$yellow: #ffef77;
$goldie: #efcb37;

$orangeCrush: #f8ab44;
$orangeJuice: #ec660d;

$redAlert: #e21534;
$redHotChilliPeppers: #ae1626;

$grapeJuice: #c94f98;
$plum: #852961;

$purpleRain: #a473af;
$deepPurple: #5f2467;

$blackSabbath: #2a2b33;
$whitesnake: #ffffff;

// TINTS (AL making these up until guidelines are ready)
$tintPurpleRain: lighten($purpleRain, 36%);
$tintMrBlueSky: lighten($mrBlueSky, 49%);
$tintGreenGreenGrass: lighten($greenGreenGrass, 56%);
$tintRedAlert: lighten($redAlert, 45%);

// OLD BRAND COLOURS AND NAMES
$rocksteady-blue: #0072CE;
$line-grey: #E2E8F0;
$ultra-pale-blue: $tintMrBlueSky;
$white: $whitesnake;
$header-black: $blackSabbath;
$text-grey: $blackSabbath;
$red: $redAlert;

$rsplay-green: #00f0b2; // non-standard color from marketing for the 'RS Play' logo (kids app)

.blue {
  color: $rocksteady-blue;
}

.yellow {
  color: $yellow;
}

body {
  color: $text-grey;
}
