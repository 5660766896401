.grown-up-form-rtv {
  padding: 0px 0 10px;
}

.grown-up-form-rtv span {
  font-weight: bold;
}

.sign-in-form {
  margin-top: 25px;
  margin-bottom: 75px;
}

.invitation-sent {
  margin-top: 50px !important;
  margin-bottom: 25px !important;
}

.ui.signed-out {
  text-align: center;
}

.forgotten-password {
  padding-top: 25px !important;
}

@media only screen and (min-width: 768px) {
  .ui.signed-out {
    margin: 1.5rem;
    margin-bottom: 0;
  }
}
