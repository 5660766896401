#address-autocomplete {
  .false-link {
    line-height: 1.8rem;
    color: #0072ce;
    text-decoration: underline;

    &:hover {
      color: #004f90;
      cursor: pointer;
    }
  
    &.disabled {
      color: grey;
      text-decoration: none;

      &:hover {
        color: grey;
        cursor: auto;
      }
    }
  }

  .disabled.field {
    opacity: 0.9;
  }
}
