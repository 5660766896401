.cb-w-100 {
  width: 100%;
  margin-bottom: 8px;
}

.mr-radio {
  margin-right: 30px;
}

.date-input {
  width: 150px;
}

.radio-date-cont {
  margin-bottom: 20px;
}

.date-p {
  display: flex;
  margin-top: 10px;
}

.date-radio {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
