html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  color: $text-grey;
  @include brandBodyFont;
}

.site-container {
  background: #fff;
  padding: 0 15px;
}

.breadcrumb {
  width: 100%;
  height: 20px;
  padding: 0;
}

.missing-user-data {
  color: #989ba4;
}

.missing-user-data {
  text-transform: none;
  color: #989ba4;
}

.ui.horizontal.divider {
  margin: 20px 0;
}

@media only screen and (max-width: 768px) {
  .ui.horizontal.divider {
    margin: 25px 0;
  }
}
