@import '../../styles/colors.scss';

.nps-page-container {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.rating-key {
  color: $purpleRain;
  padding: 0 10px 0 5px;
}

.rating-key-unlikely {
  padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  .nps-page-container {
    padding: 0;
  }
}
