.guitar-info {
  border-style: solid;
  border-width: 1px;
  border-color: #D5D5D5;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  .guitar-info {
    margin: 5px 0px;
  }
}
