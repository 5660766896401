// Brand fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@mixin brandHeading {
  font-family: "Barlow Condensed", sans-serif !important;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
}

@mixin brandSubHeading {
  font-family: "Barlow Condensed", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

@mixin brandBodyFont {
  font-family: "Barlow", sans-serif !important;
  font-weight: 500;
  font-style: normal;
}

.ui.button,
.ui.message .header {
  @include brandBodyFont;
}

h1,
h2,
h3 {
  color: $text-grey;
  margin-bottom: 21px;
}

h1 {
  @include brandHeading;
  font-size: 48px;
  line-height: 42px;
  color: $header-black;
}

h2, h3 {
  @include brandSubHeading;
}

h2 {
  font-size: 48px;
  line-height: 46px;
}

h3 {
  font-size: 18px;
  line-height: 17px;
}

h4, h5, label, .pseudo-label {
  @include brandBodyFont;
  font-size: 14px;
  color: $text-grey;
  margin-bottom: 5px;
}

h4 {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  h1, h2 {
    font-size: 24px;
    line-height: 23px;
  }
}
