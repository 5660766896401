.homepage-actions {
  padding: 0;

  p, i, .header {
    color: $rocksteady-blue;
  }

  .message:hover, .message:active {
    border: 1px solid $rocksteady-blue;
    border-radius: 4px;
  }
}

.child-summaries {
  img.ui.image {
    display: inline-block;
    margin-right: 12px;
  }

  h4 {
    font-size: 16px;
    color: $rocksteady-blue;
    padding-left: 1.5rem; // To alignt with semantic column padding
  }
}

// Settings for mobile and tablet in portrait (vertical)
@media only screen and (max-width: 768px) {
  .full-welcome {
    display: none;
  }
}
