.help-button {
  all: unset;
  cursor: pointer;
  color: #0072ce;
  text-decoration: underline;
}

.reaction-panel-button {
  border: none;
  border-radius: 20px;
  height: 37px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.reaction-panel {
  height: 42px;
  position: absolute;
  background-color: white;
  border-radius: 20px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: -40px;
  left: 34px;
  width: 230px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transform-origin: bottom left;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.reaction-panel.show {
  transform: scale(1) translateY(-10px) translateX(-10px);
}

.reaction-panel .reaction {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
  cursor: pointer;
  transition: padding-bottom 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.reaction-panel .reaction:hover {
  transform: scale(1.2);
  padding-bottom: 12px;
  align-self: flex-end;
}

.round-up-poster-image {
  position: relative;
  display: flex;
  justify-content: center;
}

.round-up-poster-image:hover .play-icon {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

/* only screen and (max-width: 767px) */
@media (max-width: 767px) {
  .reaction-panel {
    left: 20px;
  }
}
