@import './RoundUpGlobals.scss';

.Roundup,
.RoundupColorOption2,
.RoundupColorOption3 {
  transition: background-image 300ms ease-in-out, background-blend-mode 300ms ease-in-out;
  height: 100vh;
  width: 100vw;
  background-color: $Color-Skin-1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__wrapper {
    padding: 20px 20px;
    margin: auto;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-height: $size1) {
      transform: scale(0.90);
      transform-origin: top;
      padding: 20px 0;
    }
    @media only screen and (max-height: $size2) {
      transform: scale(0.80);
      transform-origin: top;
      padding: 20px 0;
    }
    @media only screen and (max-height: $size3) {
      transform: scale(0.70);
      transform-origin: top;
      padding: 20px 0;
    }
    @media only screen and (min-width: $size1) {
      max-width: 700px;
      flex: 1 0 0;
      align-self: stretch;
    }
  }
  &__button {
    &__start {
      background-color: $Color-Spot-1;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      padding: 0.924rem 1.88rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: none;
      transition: all 300ms ease-in-out;
      z-index: 10;
      clip-path: polygon(5% 10%, 97% 0, 100% 100%, 0 92%);
      button {
        outline: none;
        border: none;
        background-color: transparent;
        @extend .roundup-font-body-strong-alt;
        color: $Color-Shade;
      }
      a {
        color: $Color-Shade;
        gap: 10px;
        display: flex;
      }
    }
  }
  &__slides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    max-height: 700px;
    align-self: stretch;
    min-height: 520px;
  }
  &__navControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    z-index: 10;
    &__prev,
    &__next {
      background-color: $Color-Skin-1-Pop;
      border: none;
      color: $Color-Key-1;
      @extend .roundup-font-body-strong;
      display: flex;
      padding: 0.924rem 1.88rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: all 300ms ease-in-out;
      clip-path: polygon(5% 10%, 97% 0, 100% 100%, 0 92%);
    }
    &__prev {
      clip-path: polygon(95% 10%, 3% 0, 0 100%, 100% 92%);
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    &__exit,
    &__mute,
    &__hidden {
      background-color: $Color-Skin-1-Pop;
      svg {
        fill: $Color-Key-1;
      }
      border: none;
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: min-content;
      transition: all 300ms ease-in-out;
      img {
        width: 30px;
        height: 30px;
      }
    }
    &__hidden {
      opacity: 0;
    }
    &__logo {
      width: 78.241px;
      height: 70px;
      transition: all 300ms ease-in-out;
      z-index: 2;
      svg {
        width: 100%;
        height: 100%;
        fill: $Color-Key-1;
      }
    }
  }
}

.RoundupColorOption2 {
  &__navControls {
    &__prev,
    &__next {
      background-color: $Color-Skin-2-Pop;
      color: $Color-Spot-2;
    }
  }
  &__controls {
    &__exit,
    &__mute {
      background-color: $Color-Skin-2-Pop;
      path {
        fill: $Color-Spot-2;
      }
    }
    &__logo {
      path {
        fill: $Color-Spot-2;
      }
    }
  }
}


.RoundupColorOption3 {
  &__button {
    &__end {
      background-color: $Color-Skin-3-Pop;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      padding: 0.924rem 1.88rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: none;
      transition: all 300ms ease-in-out;
      z-index: 10;
      clip-path: polygon(5% 10%, 97% 0, 100% 100%, 0 92%);
      button {
        outline: none;
        border: none;
        background-color: transparent;
        @extend .roundup-font-body-strong;
        color: $Color-Key-1;
      }
      a {
        color: $Color-Key-1;
        gap: 10px;
        display: flex;
      }
    }
  }
  &__navControls {
    &__prev,
    &__next {
      background-color: $Color-Skin-3-Pop;
      color: $Color-Key-1;
    }
  }
  &__controls {
    &__exit,
    &__mute {
      background-color: $Color-Skin-3-Pop;
      path {
        fill: $Color-Key-1;
      }
    }
    &__logo {
      path {
        fill: $Color-Key-1;
      }
    }
  }
}
