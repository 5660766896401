.instrument-deals > .image-banner > .header {
  max-width: 280px;
}

.ui.column.grid.hire-instruments {
  margin-bottom: 14px;
  margin-top: 0;
}

.hire-instruments div.image-banner {
  margin: 0;
}

.hire-instruments .image-banner .ui.header, .buy-instruments .image-banner .ui.header {
  max-width: max-content;
}

.hire-instruments .hire-guitar .image-banner .ui.header {
  margin-right: 25px;
}

.buy-instruments div.ui.card > .image-banner {
  margin: 15px 15px 5px 15px;
  border-radius: 0 !important;
}

div.ui.popup.copy-popup {
  padding: 0.5em 1em;
}

div.ui.popup.copy-popup > button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

div.ui.left.labeled.button.normans-code-btn {
  margin: 6px 0 3px;
}

.normans-copy-btn.link {
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #4183c4;
  font-weight: normal;
}

.normans-copy-btn.link:hover {
  color: #1e70bf;
  background-color: transparent;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .hire-instruments .column:last-child > div.image-banner {
    padding-top: 111px;
  }
}

@media only screen and (max-width: 768px) {
  .buy-instruments div.ui.card > .image-banner {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .ui.cards.buy-instruments {
    margin-top: 0;
  }

  .buy-instruments div.ui.card > .image-banner {
    margin: 0 0 5px 0;
    padding-top: 100px;
  }

  .hire-instruments div.image-banner {
    padding-top: 107px;
  }
}