@import './RoundUpGlobals.scss';

.Slide,
.SlideError,
.SlideLoading,
.SlideVocals,
.SlideGuitar,
.SlideBass,
.SlideKeyboard,
.SlideDrums,
.SlideWithBigVocalsIcon,
.SlideWithBigGuitarIcon,
.SlideWithBigBassIcon,
.SlideWithBigKeyboardIcon,
.SlideWithBigDrumsIcon,
.SlideColorOption2,
.SlideColorOption3 {
  background-color: $Color-Skin-1;
  display: flex;
  max-width: 700px;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  &__section {
    &__active,
    &__inactive {
      display: flex;
      flex-direction: column;
      height: 0;
      opacity: 0;
      transition: all 0.5s ease-out;
      width: auto;
      pointer-events: none;
      max-width: 430px;
    }
    &__active {
      margin-bottom: 10px;
      height: auto;
      opacity: 1;
      pointer-events: all;
    }
  }
  &__cta {
    border-radius: 999px;
    background-color: $Color-Skin-1;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
  }
  &__kidImage {
    padding: 10px;
    position:relative;
    &__imageWrapper {
      background-color: $Color-Spot-1;
      padding: 0px 0px 10px 15px;
      margin-top: 20px;
    }
    &__button {
      background: $Color-Skin-1-Pop;
      border: none;
      padding: 10px;
      position: absolute;
      z-index: 3;
      bottom: -15px;
      right: -15px;
      svg {
        width: 30px;
        height: 30px;
        fill: $Color-Key-1;
      }
    }
    &__image {
      min-height: 160px;
      height: auto;
      max-height: 300px;
      z-index: 1;
      position: relative;
      max-width: 100%;

      position: relative;
      top: -20px;
      left: -30px;
      &__fullscreen {
        width: 100%;
        max-width: 98vw;
        height: auto;
      }
      @media only screen and (max-height: $size2) {
        max-height: 240px;
      }
      @media only screen and (max-height: $size3) {
        max-height: 200px;
      }
    }
    &__clickable {
      cursor: pointer;
      outline: none;
    }
    &__caption {
      @extend .roundup-font-body-strong;
      color: $Color-Skin-1;
      text-align: left;
      z-index: 1;
      position: relative;
      position: relative;
      margin-top: -15px;
    }
  }
  &__video {
    padding: 10px;
    position:relative;
    &__thumbnail {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: block;
      border: none;
      width: 20rem;
      background-color: transparent;
      height: 11.25rem;
      z-index: 1000;
    }
    &__reportableThumbnail {
      z-index: 1;
      position: relative;
      width: 20.49519rem;
      height: 11.56244rem;
      background: black;

      position: relative;
      top: -20px;
      left: -30px;
      &:after {
        content: '';
        display: block;
        width: 20.49519rem;
        height: 11.56244rem;
        background-image: url('./assets/icon_play.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
      }
    }
    &__info {
      background-color: $Color-Spot-1;
      padding: 0px 0px 10px 15px;
      margin-top: 20px;
      outline: none;
      border: none;
    }
    &__caption {
      @extend .roundup-font-body-strong;
      color: $Color-Skin-1;
      text-align: left;
      z-index: 1;
      position: relative;
      position: relative;
      margin-top: -15px;
    }
    &__report {
      background: $Color-Skin-1-Pop;
      border: none;
      padding: 10px;
      position: absolute;
      z-index: 3;
      bottom: -15px;
      right: -15px;
      svg {
        width: 30px;
        height: 30px;
        fill: $Color-Key-1;
      }
    }
  }
  &__reportMessage {
    margin-top: 20px;
    width: 25rem;
  }
  &__sparkles,
  &__unit__sparkles,
  &__title-large__sparkles {
    position: relative;
    span {
      background: url('./assets/sparkle.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      opacity: 0;
      animation: sparkle 4s infinite alternate;
      &:nth-of-type(1) {
        left: -10px;
        top: -5px;
        animation-delay: 250ms;
        animation-duration: 3000ms;
      }
      &:nth-of-type(2) {
        bottom: 25px;
        left: 10px;
        width: 25px;
        height: 25px;
        animation-delay: 500ms;
        animation-duration: 2500ms;
      }
      &:nth-of-type(3) {
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        animation-delay: 750ms;
        animation-duration: 3000ms;
      }
      &:nth-of-type(4) {
        bottom: 10px;
        right: 10px;
        animation-delay: 250ms;
        animation-duration: 2500ms;
      }
    }
  }
  &__unit__sparkles {
    span {
      width: 40px;
      height: 40px;
      &:nth-of-type(1) {
        left: -70px;
      }
      &:nth-of-type(2) {
        left: -40px;
        bottom: -25px;
        width: 30px;
        height: 30px;
      }
      &:nth-of-type(3) {
        right: -70px;
        width: 30px;
        height: 30px;
      }
      &:nth-of-type(4) {
        right: -50px;
        top: 50px;
      }
    }
  }
  &__title-large__sparkles {
    span {
      width: 40px;
      height: 40px;
      &:nth-of-type(1) {
        left: -50px;
        animation-delay: 450ms;
        animation-duration: 3500ms;
      }
      &:nth-of-type(2) {
        bottom: -35px;
        left: -27px;
        animation-delay: 350ms;
        animation-duration: 2000ms;
        width: 30px;
        height: 30px;
      }
      &:nth-of-type(3) {
        right: -40px;
        animation-delay: 350ms;
        animation-duration: 2000ms;
        width: 30px;
        height: 30px;
      }
      &:nth-of-type(4) {
        bottom: -30px;
        right: -30px;
        animation-delay: 850ms;
        animation-duration: 3500ms;
      }
    }
  }
  &__star {
    display: flex;
    margin-bottom: 20px;
    div {      
      flex-basis: fit-content;
    }
    p {
      text-align: left;
      max-width: 100%;
    }
    span {
      background: url('./assets/sparkle.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.875rem;
      height: 1.875rem;
      display: block;
      margin-right: 0.31rem;
    }
  }

  // FONTS

  &__micro {
    @extend .roundup-font-micro;
    color: $Color-Key-1;
    text-align: center;
    margin: 0;
  }
  &__body {
    @extend .roundup-font-body;
    color: $Color-Key-1;
    text-align: center;
    margin: 20px 0 5px 0;
    span {
      @extend .roundup-font-body-emphasis;
      font-style: italic;
    }
    bold {
      font-weight: 700;
    }
  }
  &__body-highlight {
    @extend .roundup-font-body;
    color: $Color-Key-2;
    text-align: center;
    margin: 0px 0 5px 0;
    span {
      @extend .roundup-font-body-emphasis;
      font-style: italic;
    }
  }
  &__body-emphasis {
    @extend .roundup-font-body-emphasis;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0;
    span {
      @extend .roundup-font-body-emphasis;
      font-style: italic;
    }
    bold {
      font-weight: 700;
    }
  }
  &__body-strong {
    @extend .roundup-font-body-strong;
    color: $Color-Key-1;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 700;
    &__huge {
      @extend .Slide__body-strong;
      font-size: 0.75rem;
    }
    &__large {
      @extend .Slide__body-strong;
      font-size: 0.875rem;
    }
    &__medium {
      @extend .Slide__body-strong;
      font-size: 1rem;
    }
    &__small {
      @extend .Slide__body-strong;
      font-size: 1.125rem;
    }
  }
  &__body-strong-alt {
    @extend .roundup-font-body-strong;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0;
    &__huge {
      @extend .Slide__body-strong-alt;
      font-size: 0.75rem;
    }
    &__large {
      @extend .Slide__body-strong-alt;
      font-size: 0.875rem;
    }
    &__medium {
      @extend .Slide__body-strong-alt;
      font-size: 1rem;
    }
    &__small {
      @extend .Slide__body-strong-alt;
      font-size: 1.125rem;
    }
  }
  &__head-small {
    @extend .roundup-font-head-small;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0;
  }
  &__head-small-alt {
    @extend .roundup-font-head-small;
    color: $Color-Key-1;
    text-align: center;
    margin: 0;
  }
  &__head-small-highlight {
    @extend .roundup-font-head-small;
    color: $Color-Key-2;
    text-align: center;
    margin: 0;
  }
  &__head-small-emphasis {
    @extend .roundup-font-head-small-emphasis;
    color: $Color-Key-2;
    text-align: center;
    margin: 0;
  }
  &__head-small-body {
    @extend .roundup-font-head-small-emphasis;
    color: $Color-Key-1;
    text-align: center;
    margin: 0;
  }
  &__head-emphasis,
  &__head-emphasis-alt {
    @extend .roundup-font-head-emphasis;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__head-emphasis-alt {
    color: $Color-Key-1;
  }
  &__head-larger-emphasis {
    @extend .roundup-font-head-larger-emphasis;
    color: $Color-Key-1;
    text-align: center;
    margin: 0;
  }
  &__title {
    @extend .roundup-font-title;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__title-strong {
    @extend .roundup-font-title-strong;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0 0 0.63rem 0;
  }
  &__title-strong-alt {
    @extend .roundup-font-title-strong-alt;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 0.63rem 0;
  }
  &__title-large,
  &__title-large__larger,
  &__title-large__medium {
    @extend .roundup-font-title-large;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__title-large__medium {
    font-size: 2.813rem;
  }
  &__title-large__larger {
    font-size: 3.125rem;
  }
  &__title-large-alt {
    @extend .roundup-font-title-large;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0;
    font-size: 2.1875rem;
    line-height: 100%; /* 2.1875rem */
    letter-spacing: 0.06563rem;
    text-transform: uppercase;
  }
  &__title-large-highlight {
    @extend .roundup-font-title-large;
    color: $Color-Skin-1;
    text-align: center;
    margin: 0 0 5px 0;
  }
  &__subTitle {
    @extend .roundup-font-subTitle;
    color: $Color-Key-2;
    text-align: center;
    margin: 0;
  }
  &__subTitle-emphasis-alt {
    @extend .roundup-font-subTitle-emphasis-alt;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0 !important; // !important to override the margin from Semantic UI
  }
  &__subTitle-emphasis {
    @extend .roundup-font-subTitle-emphasis;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0 !important; // !important to override the margin from Semantic UI
  }
  &__subTitle-emphasis-2 {
    @extend .roundup-font-subTitle-emphasis;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0 0 5px 0 !important; // !important to override the margin from Semantic UI
  }
  &__subTitle-emphasis-alt-2 {
    @extend .roundup-font-subTitle-emphasis-alt-2;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0 0 5px 0 !important; // !important to override the margin from Semantic UI
  }
  &__unit {
    @extend .roundup-font-head-larger-emphasis;
    color: $Color-Spot-1;
    text-align: center;
    margin: 0 0 0 0;
  }
  &__measurement {
    @extend .roundup-font-title-large;
    color: $Color-Key-2;
    text-align: center;
    margin: 0 0 5px 0;
  }
}


.SlideWithBigVocalsIcon,
.SlideWithBigGuitarIcon,
.SlideWithBigBassIcon,
.SlideWithBigKeyboardIcon,
.SlideWithBigDrumsIcon {
  position: relative;
  &.IntroSlide {
    background: url('./assets/Light-Rig.svg') no-repeat top center;
    background-size: inherit;
    background-color: $Color-Skin-1;
    & .SlideWithFlowers__sparkles {
      height: 170px;
      display: flex;
    }
  }
  &:after,
  &:before {
    content: '';
    width: 6.25rem;
    height: 6.25rem;
    display: block;
    background-image: url('./assets/kid_assets/vocals/Icon-Vocal.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center center;
    animation-name: pulse2;
    animation-duration: 2s;
    opacity: 0.8;
  }
  &:after {
    right: 0px;
    left: auto;
    animation-duration: 1500ms;
    animation-name: pulse1;
  }
  &__special {
    position: relative;
    span {
      background-image: url('./assets/kid_assets/vocals/Icon-Vocal.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 5rem;
      height: 5rem;
      display: block;
      position: absolute;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform-origin: center center;
      z-index: -1;
      opacity: 0.8;
      &:nth-of-type(1) {
        animation-name: pulse2;
        animation-duration: 2s;
        left: -50px;
        top: 40px;
        scale: 0;
        z-index: -1;
      }
      &:nth-of-type(2) {
        animation-name: pulse1;
        animation-duration: 1s;
        right: -50px;
        top: 40px;
        scale: 0;
        z-index: -1;
      }
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        display: none;
      }
    }
  }

}

.SlideWithBigGuitarIcon {
  &:after,
  &:before {
    background-image: url('./assets/kid_assets/guitar/Icon-Guitar.svg');
  }
  &__special {
    span {
      background-image: url('./assets/kid_assets/guitar/Icon-Guitar.svg');
    }
  }
}

.SlideWithBigBassIcon {
  &:after,
  &:before {
    background-image: url('./assets/kid_assets/bass/Icon-Bass.svg');
  }
  &__special {
    span {
      background-image: url('./assets/kid_assets/bass/Icon-Bass.svg');
    }
  }
}

.SlideWithBigKeyboardIcon {
  &:after,
  &:before {
    background-image: url('./assets/kid_assets/keyboard/Icon-Keyboard.svg');
  }
  &__special {
    span {
      background-image: url('./assets/kid_assets/keyboard/Icon-Keyboard.svg');
    }
  }
}

.SlideWithBigDrumsIcon {
  &:after,
  &:before {
    background-image: url('./assets/kid_assets/drums/Icon-Drums.svg');
  }
  &__special {
    span {
      background-image: url('./assets/kid_assets/drums/Icon-Drums.svg');
    }
  }
}


.SlideVocals,
.SlideGuitar,
.SlideBass,
.SlideKeyboard,
.SlideDrums {
  &__special {
    position: relative;
    span {
      background-image: url('./assets/kid_assets/vocals/Icon-Vocal.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 5rem;
      height: 5rem;
      display: block;
      position: absolute;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform-origin: center center;
      z-index: -1;
      opacity: 0.8;
      &:nth-of-type(1) {
        animation-name: pulse2;
        animation-duration: 3s;
        left: -50px;
        top: 40px;
        scale: 0;
        z-index: -1;
      }
      &:nth-of-type(2) {
        animation-name: pulse1;
        animation-duration: 2s;
        right: -50px;
        top: 40px;
        scale: 0;
        z-index: -1;
      }
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        display: none;
      }
    }
  }
}

.SlideGuitar {
  &__special {
    span {
      background-image: url('./assets/kid_assets/guitar/Icon-Guitar.svg');
    }
  }
}
.SlideBass {
  &__special {
    span {
      background-image: url('./assets/kid_assets/bass/Icon-Bass.svg');
    }
  }
}
.SlideKeyboard {
  &__special {
    span {
      background-image: url('./assets/kid_assets/keyboard/Icon-Keyboard.svg');
    }
  }
}
.SlideDrums {
  &__special {
    span {
      background-image: url('./assets/kid_assets/drums/Icon-Drums.svg');
    }
  }
}

.SlideColorOption2 {
  background-color: $Color-Skin-2;
  &__micro,
  &__body-emphasis,
  &__body-strong,
  &__body-strong-emphasis,
  &__body-stronger-emphasis,
  &__head-small-emphasis,
  &__head,
  &__head-emphasis,
  &__head-larger-emphasis,
  &__unit,
  &__measurement,
  &__title-large,
  &__title-strong-alt {
    color: $Color-Spot-2;
  }
  &__body {
    color: $Color-Spot-2;
  }
  &__head-small {
    color: $Color-Spot-2;
  }
  &__title-large-highlight {
    margin: 0 0 0 0;
  }
  &__img {
    max-width: 150px;
  }
  @media only screen and (max-height: $size1) {
    &__img {
      width: 50vmin;
    }
  }
}

.SlideColorOption3 {
  background-color: $Color-Skin-3;
  &__micro,
  &__body-emphasis,
  &__body-strong,
  &__body-strong-emphasis,
  &__body-stronger-emphasis,
  &__head-small-emphasis,
  &__head,
  &__head-emphasis,
  &__head-larger-emphasis,
  &__unit {
    color: $Color-Key-2;
  }
  &__body {
    color: $Color-Key-1;
  }
  .SlideVocals__head-small-emphasis,
  .SlideGuitar__head-small-emphasis,
  .SlideBass__head-small-emphasis,
  .SlideKeyboard__head-small-emphasis,
  .SlideDrums__head-small-emphasis,
  &__head-small-emphasis {
     color: $Color-Spot-3;
  }
  &__head-small {
    color: $Color-Key-1;
  }
  &__measurement {
    color: $Color-Key-1;
  }
  &__title-large-highlight {
    margin: 0 0 0 0;
  }
  &__head-small-highlight {
    color: $Color-Spot-3;
  }
  &__img {
    max-width: 150px;
  }
  &__kidImage {
    &__button {
      background: $Color-Skin-3-Pop;
    }
    &__caption {
      color: $Color-Skin-3;
    }
  }
  
  @media only screen and (max-height: $size1) {
    &__img {
      width: 50vmin;
    }
  }
}

.SlideError {
  background-color: $Color-Skin-1;
  p {
    @extend .roundup-font-body;
    color: $Color-Key-1;
    text-align: center;
    margin: 20px 0 20px 0;
  }
  &__button {
    background-color: $Color-Key-1;
    color: $Color-Skin-1;
    @extend .roundup-font-body;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border: none;
    gap: 10px;
    transform: skew(-15deg);

  }
  &__controls {
    &__mute,
    &__hidden {
      opacity: 0;
    }
  }
}

.SlideLoading {
  background-color: $Color-Skin-1;
  height: 270px;
   &__loader {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    &:after {
      border-color: $Color-Key-1 transparent transparent !important;
    }
  }
}

@keyframes sparkle {
	50% {
		opacity: 0.4;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes pulse1 {
  0% {
    rotate: 0;
    scale: 1;
  }
  50% {
    scale: 1.2;
    rotate: 30deg;
  }
  100% {
    rotate: 0deg;
    scale: 1;
  }
}

@keyframes pulse2 {
  0% {
    rotate: 0deg;
    scale: 1;
  }
  50% {
    scale: 1.3;
    rotate: -20deg;
  }
  100% {
    rotate: 0deg;
    scale: 1;
  }
}
