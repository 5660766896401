@import '../../styles/colors.scss';

.ui.card.instrument-deal {
  background-color: transparent;

  .content, .extra.content, .ui.image {
    padding-left: 0;
    padding-right: 0;
  }

  .content>.header:not(.ui) {
    text-transform: uppercase;
    color: $deepPurple;
    font-family: "Barlow Condensed", sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
  }

  .extra>.ui.button.blue {
    font-weight: 400;
    color: white;
    background-color: $mrBlueSky;
  }
}

@media (max-width: 1199px) and (min-width: 769px) {
  .ui.grid>.row>.column.vocals-content {

    .extra.content {
      padding-bottom: 0 !important;
    }
  }
}

@media (min-width: 769px) {
  .ui.grid>.row>.column.vocals-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .content {
      padding-top: 0;
    }
  }
}

@media (max-width: 768px) {
  .ui.grid>.row>.column.vocals-image {
    padding-bottom: 5px !important;
    padding-top: 0px !important;
  }

  .ui.grid>.row>.column.vocals-content {
    padding-top: 0px !important;
  }
}
