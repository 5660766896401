.flex {
  display: flex;
}

.modal-black {
  color: #292B32 !important;
}

.plain-button {
  all: unset;
  cursor: pointer;
}

.modal-header {
  width: 100%;
  margin-left: 2px;
  justify-content: space-between;
}

.header-text {
  margin-left: 10px !important;
}

.modal-submit {
  width: auto;
}

.modal-title {
  font-size: 36px !important;
}

.cross-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 18px;
}

@media (max-width: 768px) {
  .modal-submit {
    width: 100%;
  }

  .cross-button {
    margin-top: -4px;
  }

  .modal-intro {
    padding: 0px !important
  }
}
